.vxeq{
    animation: slideUp 1s 2 ease-out;
}
@keyframes slideUp {
    0% {
      transform: translateY(100%);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }