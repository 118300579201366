/* @import url(https://fonts.googleapis.com/css?family=Raleway); */

*, *:before, *:after{
    margin: 0;
    padding: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing:border-box;
    box-sizing: border-box;
  }
  
  
  
  .main-title{
    color: #2d2d2d;
    text-align: center;
    text-transform: capitalize;
    padding: 0.7em 0;
  }
  
  .container{
    padding: 1em 0;
    float: left;
    width: 50%;
  }
  @media screen and (max-width: 640px){
    .container{
      display: block;
      width: 100%;
    }
  }
  
  @media screen and (min-width: 900px){
    .container{
      width: 33.33333%;
    }
  }
  
  .container .title{
    color: #1a1a1a;
    text-align: center;
    margin-bottom: 10px;
  }
  
  .content {
    position: relative;
    width: 90%;
    max-width: 400px;
    margin: auto;
    overflow: hidden;
    border: 10px inherit inherit;
    border-radius: 10px;
  }
  
  .content .content-overlay {
    background: rgba(0,0,0,0.7);
    position: absolute;
    height: 99%;
    width: 100%;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
    -webkit-transition: all 0.4s ease-in-out 0s;
    -moz-transition: all 0.4s ease-in-out 0s;
    transition: all 0.4s ease-in-out 0s;
  }
  
  .content:hover .content-overlay{
    opacity: 1;
  }
  
  .content-image{
    width: 100%;
  }
  
  .content-details {
    position: absolute;
    text-align: center;
    padding-left: 1em;
    padding-right: 1em;
    width: 100%;
    top: 50%;
    left: 50%;
    opacity: 0;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-transition: all 0.3s ease-in-out 0s;
    -moz-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
  }
  
  .content:hover .content-details{
    top: 50%;
    left: 50%;
    opacity: 1;
  }
  
  .content-details h3{
    color: #fff;
    font-weight: 500;
    letter-spacing: 0.15em;
    margin-bottom: 0.5em;
    text-transform: uppercase;
  }
  
  .content-details p{
    color: #fff;
    font-size:20px;
  }
  
  .fadeIn-bottom{
    top: 80%;
  }
  
  #app{
    text-align:center;
    font-size:50px;
    background-image: linear-gradient(
      -225deg,
      #231557 0%,
      #44107a 29%,
      #ff1361 67%,
      #fff800 100%
    );
    background-clip:border-box;
     text-fill-color: transparent;
    background-clip:text;
    -webkit-background-clip:text;
    -webkit-text-fill-color: transparent;
    text-fill-color: transparent;
    animation: textclip 2s linear infinite;
  }
  
  @keyframes textclip {
    to {
      background-position: 200% center;
    }
  }  