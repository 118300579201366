
.pic{
    height:150px;
    width:150px;
    border:3px solid inherit;
    border-radius:100px;
    filter: grayscale(60%);
    box-shadow: 5px 5px lightgray;
    
}

.pic:hover{
    filter: grayscale(0);
   
}
.text{
    color:gray
}
.text:hover{
    color:black
}
